import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const SpecificParameterSection = props => {
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [record_id] = hooks.useFormState('record_id')

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(record_id), [record_id])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])


  const handleCreate = useCallback(() =>
    window.open(`/specificparameter/create?companycontract_id=${record_id}`, '_blank')
    [record_id])

  const handleRowClick = useCallback((rowId, row) =>
    window.open(`/specificparameter/${_.get(row, 'specific.specificparameter_id')}?companycontract_specificparameter_id=${rowId}`)
    , [])

  return (
    <SearchFrame
      model_name={'companycontractspecificparameters'}
      populate={['specific', 'commercialpackage']}
      createButtonProps={{
        label: I18n.t('specificparameter.add', { count: 1 }),
        onClick: handleCreate
      }}
    >
      <SearchForm
        disableButton
        submitOnMount
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable
        formProps={{
          recordToForm,
          formToRecord,
          disableParentSubmitListener: !isManagingRecord
        }}
        tableProps={{
          onRowClick: null,
          enableToolbar: false,
          enablePagination: false,
          enableRowActions: true,
          enableRowSelection: false,
          enableGlobalActions: true,
          enableGlobalActionsPlacement: 'bottom',
          disableCancelRowLabel: true,
          onRowClick: !!isReadOnly && handleRowClick
        }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame>

  )
}

export default React.memo(SpecificParameterSection)
